<template>
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/zhongxinGH0.png" alt="" />
    <div class="btngroup clearfix">
      <!-- <router-link to="/zhongkeYC" :class="$route.path === '/zhongkeYC'? 'active': ''">
        <div class="companyIntro">
          <p>中科医创</p>
        </div>
      </router-link> -->
      <router-link to="/zhongxinGH"  :class="$route.path === '/zhongxinGH'? 'active': ''">
        <div class="structure">中鑫广汇</div>
      </router-link>
      <router-link to="/yilianGl"  :class="$route.path === '/yilianGl'? 'active': ''">
        <div class="structure">亿联管理</div>
      </router-link>
      <router-link to="/chanyeYQ"  :class="$route.path === '/chanyeYQ'? 'active': ''">
        <div class="structure">产业园区</div>
      </router-link>
    </div>
    <div class="hprofile">
      <h3 class="conh3">COMPANY PROFILE</h3>
      <h4 class="conh4">公司简介</h4>
      <img src="../../assets/img/index/companyIntro17.png" alt="">
      <h5>中关村高新技术企业</h5>
      <p>中科医创科技有限公司成立于2019年，坐落于北京CBD商圈，注册资金5000万元人民币，为亿联体集团全资子公司。公司以CRO、PBM 、数字健康社群营销、三大主营业务构筑商业蓝图。公司以北京为中心向全国进行产业辐射，先后在上海、杭州、连云港等地成立分公司并在澳大利亚墨尔本成立海外医学办事处负责多国际临床试验业务。</p>
      <p>公司产研部门47人占比高达40%，自主开发并拥有知识产权系统3个,软件著作权8个，9类商标1个，其中数字 健康社群营销系统（彼仙草）拥有互联网电子商务及信息服务全牌照。</p>
    </div>
    <div class="yj">
      <h4><span></span>全链条服务支持</h4>
      <div class="support">
        <img src="../../assets/img/index/companyIntro19.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>临床开发计划</h5>
            <ul>
              <li><span></span>临床开发计划</li>
              <li><span></span>临床试验设计</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>注册事务</h5>
            <ul>
              <li><span></span>药政法规咨询</li>
              <li><span></span>资料翻译</li>
              <li><span></span>申报资料审核完善</li>
              <li><span></span>审评进度跟踪</li>
              <li><span></span>人类遗传办申报</li>
              <li><span></span>IND/NDA申报</li>
            </ul>
          </div>
        </div>
        <img src="../../assets/img/index/companyIntro18.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>临床服务</h5>
            <ul>
              <li><span></span>研究中心可行性分析</li>
              <li><span></span>项目实施管理</li>
              <li><span></span>项目监查</li>
              <li><span></span>项目质量控制</li>
              <li><span></span>GCP、注册法规培训</li>
              <li><span></span>中心管理组织SMO</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>医学事务</h5>
            <ul>
              <li><span></span>医学顾问</li>
              <li><span></span>医学写作</li>
              <li><span></span>医学监察</li>
              <li><span></span>药物警戒</li>
            </ul>
          </div>
        </div>
        <img src="../../assets/img/index/companyIntro20.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>数据管理与统计分析</h5>
            <ul>
              <li><span></span>数据管理与统计计划</li>
              <li><span></span>数据核查、数据质量控制</li>
              <li><span></span>统计分析报告撰写</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>数字化健康社群</h5>
            <ul>
              <li><span></span>用户UGC内容分享</li>
              <li><span></span>医生PGC付费内容输出</li>
              <li><span></span>企业广告投放</li>
              <li><span></span>医院资源整合</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="yj2">
      <h4><span></span>业务板块</h4>
      <div class="bus">
        <img src="../../assets/img/index/companyIntro22.png" alt="">
        <h3>CRO业务(临床实验研究） </h3>
        <p>致力于成为CRO行业领导者，为健康事业持续创造新的价值。与推动行业发展的合作伙伴一道，整合医疗行业资源，探索创新业务体系，提供优质的临床试验技术，以专业规范的一站式CRO服务体系，帮助客户高效的开展医药服务。</p>
      </div>
      <div class="bus">
        <img src="../../assets/img/index/companyIntro21.png" alt="">
        <h3>PBM业务(慢病管理）  </h3>
        <p>以患者为中心，通过运用慢病管理，处方收集，数据分析、院外处方分流，医疗数据实时传输、智能分析等方式将医院、社区卫生机构数据打通，优化患者就医与康复流程，打造基层患者服务网络，构建和谐优质的患者服务闭环。</p>
      </div>
      <div class="bus">
        <img src="../../assets/img/index/companyIntro23.png" alt="">
        <h3>数字化健康社群（彼仙草健康平台）  </h3>
        <p>以服务普通C端用户为根本点，社群运营、精准营销为支撑，构建医患沟通纽带为基石的全场景生态化的线上健康互助社区。上游集合了孵化医生IP、医院患者定向管理、企业营销服务，下游为患者和精准人群提供高质量刚需服务和内容。</p>
      </div>
    </div>
    <div class="yj3">
      <h4><span></span>综合优势</h4>
      <div class="advantage" v-for="(item,index) in advantage" :key="index">
        <img :src="item.img" alt="">
        <h4>{{item.h4}}</h4>
        <p>{{item.ptext}}</p>
      </div>
    </div>
  </div>
  <!--中科医创 -->
  <div class="main" v-else>
    <img src="../../assets/img/index/img (32).png" alt="" />
    <div class="hatchTotalNav">
      <div class="hatchleft">
        <div class="hatchleftContent">
          <p>INCUBATION ENTERPRISE</p>
          <p>孵化企业</p>
        </div>
      </div>
      <div class="hatchNavRight">
        <div class="hatchNavRightContent">
          <router-link
            to="/zhongkeYC"
            :class="
              $route.path === '/zhongkeYC' ? 'companyActive' : 'companyDefault'
            "
          >
            <div>
              <p>中科医创</p>
              <div class="bluebottom"></div>
            </div>
          </router-link>
          <router-link to="/zhongxinGH">
            <div>中鑫广汇</div>
          </router-link>
          <router-link to="/yilianGl">
            <div>亿联体事业部</div>
          </router-link>
          <router-link to="/chanyeYQ">
            <div>
              <p>产业园区</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="contentMain">
      <!-- 公司简介 -->
      <div class="companyIntro">
        <div class="IntroLeft">
          <h4>COMPANY PROFILE</h4>
          <h4>公司简介</h4>
          <p>中关村高新技术企业</p>
          <p>
            中科医创科技有限公司成立于2019年，坐落于北京CBD商圈，注册资金5000万元人民币，为亿联体集团全资子公司。公司以CRO、PBM
            、数字健康社群营销、三大主营业务构筑商业蓝图。公司以北京为中心向全国进行产业辐射，先后在上海、杭州、连云港等地成立分公司并在澳大利亚墨尔本成立海外医学办事处负责多国际临床试验业务。
          </p>
          <p>
            公司产研部门47人占比高达40%，自主开发并拥有知识产权系统3个,软件著作权8个，9类商标1个，其中数字
            健康社群营销系统（彼仙草）拥有互联网电子商务及信息服务全牌照。
          </p>
        </div>
        <div class="introRight">
          <img src="../../assets/img/index/img (33).png" alt="" />
        </div>
      </div>
      <!--  全链条服务支持 -->
      <div class="serveSupport">
        <div class="serveSupportTop">
          <p>全链条服务支持</p>
          <div class="blueBttom"></div>
        </div>
        <div class="serveSupportBottom">
          <div class="img">
            <img src="../../assets/img/index/img (34).png" alt="" />
            <p>临床开发计划</p>
            <div class="hoverResult">
              <h4>临床开发计划</h4>
              <h4>临床试验设计</h4>
            </div>
          </div>
          <div class="img">
            <img src="../../assets/img/index/img (35).png" alt="" />
            <p>临床服务</p>
            <div class="hoverResult">
              <h4>药政法规咨询</h4>
              <h4>资料翻译</h4>
              <h4>申报资料审核完善</h4>
              <h4>审评进度跟踪</h4>
              <h4>人类遗传办申报</h4>
              <h4>IND/NDA申报</h4>
            </div>
          </div>
          <div class="img">
            <img src="../../assets/img/index/img (36).png" alt="" />
            <p>注册事务</p>
            <div class="hoverResult">
              <h4>研究中心可行性分析</h4>
              <h4>项目实施管理</h4>
              <h4>项目监查</h4>
              <h4>项目质量控制</h4>
              <h4>GCP、注册法规培训</h4>
              <h4>中心管理组织SMO</h4>
            </div>
          </div>
          <div class="img">
            <img src="../../assets/img/index/img (37).png" alt="" />
            <p>医学事务</p>
            <div class="hoverResult">
              <h4>医学顾问</h4>
              <h4>医学写作</h4>
              <h4>医学监察</h4>
              <h4>药物警戒</h4>
            </div>
          </div>
          <div class="img">
            <img src="../../assets/img/index/img (38).png" alt="" />
            <p>数据管理与统计分析</p>
            <div class="hoverResult">
              <h4>数据管理与统计计划</h4>
              <h4>数据核查、数据质量控制</h4>
              <h4>统计分析报告撰写</h4>
            </div>
          </div>
          <div class="img">
            <img src="../../assets/img/index/img (39).png" alt="" />
            <p>数字化健康社群</p>
            <div class="hoverResult">
              <h4>用户UGC内容分享</h4>
              <h4>医生PGC付费内容输出</h4>
              <h4>企业广告投放</h4>
              <h4>医院资源整合</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- 业务板块 -->
      <div class="croBusiness">
        <p>业务板块</p>
        <div class="blueBttom"></div>
      </div>
      <!-- CRO业务（临床试验研究） -->
      <div class="cro">
        <div class="crobottom">
          <div class="crobottomTotal">
            <div class="croLeft">
              <h4>CRO业务（临床试验研究）</h4>
              <p>
                致力于成为CRO行业领导者，为健康事业持续创造新的价值。与推动
                行业发展的合作伙伴一道，整合医疗行业资源，探索创新业务体系，
                提供优质的临床试验技术，以专业规范的一站式CRO服务体系，帮助
                客户高效的开展医药服务。
              </p>
            </div>
            <div class="croRight">
              <img src="../../assets/img/index/img (40).png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- PBM业务（慢病管理） -->
      <div class="pbm">
        <div class="pbmLeft">
          <img src="../../assets/img/index/img (41).png" alt="" />
        </div>
        <div class="pbmRight">
          <h4>PBM业务（慢病管理）</h4>
          <p>
            以患者为中心，通过运用慢病管理，处方收集，数据分析、院外处方分
            流，医疗数据实时传输、智能分析等方式将医院、社区卫生机构数据打
            通，优化患者就医与康复流程，打造基层患者服务网络，构建和谐优质
            的患者服务闭环。
          </p>
        </div>
      </div>
      <!-- 数字化健康社群（彼仙草健康平台） -->
      <div class="healthGroup">
        <div class="healthGroupbottom">
          <div class="healthGroupbottomTotal">
            <div class="healthGroupLeft">
              <h4>数字化健康社群（彼仙草健康平台）</h4>
              <p>
                以服务普通C端用户为根本点，社群运营、精准营销为支撑，构建医
                患沟通纽带为基石的全场景生态化的线上健康互助社区。上游集合了
                孵化医生IP、医院患者定向管理、企业营销服务，下游为患者和精准
                人群提供高质量刚需服务和内容。
              </p>
            </div>
            <div class="healthGroupRight">
              <img src="../../assets/img/index/img (42).png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 综合优势 -->
      <div class="advantage">
        <div class="advantageTop">
          <h4>综合优势</h4>
          <div class="blueBottom"></div>
        </div>
        <ul class="advantageBottom">
          <li>
            <img src="../../assets/img/index/imgZH (1).png" alt="" />
            <h4>良好的专家关系</h4>
            <p>与各领域专家有良好的合作关系，特别是肿瘤领域的专家 拷贝</p>
          </li>
          <li>
            <img src="../../assets/img/index/imgZH (2).png" alt="" />
            <h4>强大的IT团队</h4>
            <p>开发了远程会诊及中心影像系统、患者院后管理系统</p>
          </li>
          <li>
            <img src="../../assets/img/index/imgZH (3).png" alt="" />
            <h4>产教研一站式服务</h4>
            <p>赋能产品开发，并组建课题研究，进行大健康教育</p>
          </li>
          <li>
            <!-- <img src="../../assets/img/index/imgZH (4).png" alt="" /> -->
            <h4>精准化营销体系</h4>
            <p>以服务普通C端用户为根本点，O2O引流构建可医生IP孵化的互助社群</p>
          </li>
          <li>
            <img src="../../assets/img/index/imgZH (5).png" alt="" />
            <h4>全病程数字化管理</h4>
            <p>疾病管理智能引擎+云端患教知识库</p>
          </li>
          <li>
            <img src="../../assets/img/index/imgZH (6).png" alt="" />
            <h4>丰富的临床资源</h4>
            <p>与全国多家医院保持长期良好的合作</p>
          </li>
          <li>
            <img src="../../assets/img/index/imgZH (7).png" alt="" />
            <h4>国内外专业协同</h4>
            <p>海外注册和临床试验团队助力国际多中心临床试验和中澳双申报</p>
          </li>
          <li>
            <img src="../../assets/img/index/imgZH (8).png" alt="" />
            <h4>高效的项目团队</h4>
            <p>项目经验丰富，执行力强</p>
          </li>
          <li>
            <img src="../../assets/img/index/imgZH (9).png" alt="" />
            <h4>强执行力</h4>
            <p>严格遵循各环节SOP，确保流程规范与高质量数据</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      advantage:[
        {
          img:require('../../assets/img/index/companyIntro30.png'),
          h4:'良好的专家关系',
          ptext:'与各领域专家有良好的合作关系，特别是肿瘤领域的专家'
        },
        {
          img:require('../../assets/img/index/companyIntro31.png'),
          h4:'强大的IT团队',
          ptext:'开发了远程会诊及中心影像系统、患者院后管理系统'
        },
        {
          img:require('../../assets/img/index/companyIntro32.png'),
          h4:'产教研一站式服务',
          ptext:'赋能产品开发，并组建课题研究，进行大健康教育'
        },
        {
          img:require('../../assets/img/index/companyIntro33.png'),
          h4:'精准化营销体系',
          ptext:'以服务普通C端用户为根本点，O2O引流构建可医生IP孵化的互助社群'
        },
        {
          img:require('../../assets/img/index/companyIntro34.png'),
          h4:'全病程数字化管理',
          ptext:'疾病管理智能引擎+云端患教知识库'
        },
        {
          img:require('../../assets/img/index/companyIntro35.png'),
          h4:'丰富的临床资源',
          ptext:'与全国多家医院保持长期良好的合作'
        },
        {
          img:require('../../assets/img/index/companyIntro36.png'),
          h4:'国内外专业协同',
          ptext:'海外注册和临床试验团队助力国际多中心临床试验和中澳双申报'
        },
        {
          img:require('../../assets/img/index/companyIntro37.png'),
          h4:'高效的项目团队',
          ptext:'项目经验丰富，执行力强'
        },
        {
          img:require('../../assets/img/index/companyIntro38.png'),
          h4:'强执行力',
          ptext:'严格遵循各环节SOP，确保流程规范与高质量数据'
        },
      ]
    }
  }
};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }

      .btngroup{
        width: 6.8rem;
        padding-left:0.35rem;
        padding-right:0.35rem; 
        a{
          display: block;
          width: 2rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.4rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        }
        a.active{
          background: #0085D0;
          color: #ffffff;
        }
        a:nth-child(3){
          margin-right:0;
        }
      }
      .hprofile{
        display block
        width calc( 100% - 0.36rem )
        padding-left 0.36rem
        background #F5F5F5
        padding-bottom 0.2rem

        img{
          display: block
          width: 5rem
          height: 3rem
          // margin-bottom: 0.24rem
        }
        
        h5{
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0085D0;
          padding-top: 0.24rem;
          padding-bottom:0.2rem;
        }
        p{
          width: 6.86rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
          margin-bottom:0.1rem;
          word-break:break-all;
        }
      }
      .yj3{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.05rem;
        border-bottom:0.32rem solid #f5f5f5;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.3rem;
          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }
        }
        .advantage{
          display: block;
          
          img{
            display: block;
            width:1.02rem;
            height:1.02rem;
            margin:0 auto;
            // border:0.01rem dashed #666;
          }
          h4{
            // width: 1.79rem;
            font-size: 0.26rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            padding-top: 0.22rem;
            padding-bottom:0.15rem;
            margin:0 auto;
            text-align: center;
          }
          p{
            width: 5.5rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 0.39rem;
            margin:0 auto;
            text-align: center;
            margin-bottom:0.2rem;
          }
        }
      }
      .yj2{
        border-top: 0.32rem solid #f5f5f5;
        border-bottom:0.32rem solid #f5f5f5;
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.05rem;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.32rem;
          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }
        }
        .bus{
          width:6.8rem;
          img{
            display: block;
            width:6.8rem;
            height:3.5rem;
          }
          h3{
            font-size: 0.3rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            // line-height: 0.45rem;
            margin-top:0.32rem;
            margin-bottom:0.2rem;
          }
          p{
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.45rem;
            padding-bottom:0.2rem;
          }

        }
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.05rem;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.32rem;
          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }
        }
        .support{
          display: block;
          width:6.8rem;

          img{
            display: block;
            width: 100%;
            height:2rem;
          }
          .supportcon{
            display: block;
            width: 100%;
            .con_left{
              display: block;
              width:50%;
              float:left;
              h5{
                  font-size: 0.26rem;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 0.3rem;
                  padding-top:0.24rem;
              }
              ul{
                padding-top:0.24rem;
                padding-bottom:0.15rem;
                li{
                  font-size: 0.24rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                  line-height: 0.3rem;
                  display: flex;
                  align-items: center;
                  margin-bottom:0.15rem;
                  span{
                    display: inline-block;
                    width: 0.04rem;
                    height: 0.04rem;
                    background: #0085D0;
                    border-radius: 50%;
                    margin-right:0.12rem;
                  }
                }
              }
            }
          }
        }

      }
      .conh3{
        padding-top: 0.4rem
        font-size: 0.24rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .conh4{
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        padding 0.15rem 0 0.24rem 0
      }
    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      font-family: Source Han Sans CN;

      >img {
        margin-top: 100px;
        width: 100%;
      }

      .hatchTotalNav {
        position: relative;
        display: flex;
        margin: 0 auto;
        width: 1280px;

        .hatchleft {
          position: absolute;

          // top: -62px;
          // width: 645px;
          .hatchleftContent {
            width: 800px;
            height: 162px;
            background: linear-gradient(0deg, #0085D0, #09B7CF);
            position: absolute;
            top: -63px;
            left: -471px;

            p:nth-child(1) {
              height: 19px;
              padding: 53px 0 15px 456px;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }

            p:nth-child(2) {
              width: 118px;
              height: 29px;
              margin-left: 456px;
              font-size: 29px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 39px;
            }
          }
        }

        .hatchNavRight {
          width: 1280px;
          margin: 0 auto;

          // 右边导航
          .hatchNavRightContent {
            flex: 1;
            color: #666666;
            box-sizing: border-box;
            height: 100px;
            background: #FFFFFF;
            border-right: 0px solid #8df;
            box-shadow: 0px 2px 6px 0px rgba(209, 209, 209, 0.65);
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            top: -1px;
            left: 329px;
            padding-right: 240px;

            // width: 1280px;

            // margin-right: auto;
            a:hover {
              div {
                color: #0085D0;
              }

              div:nth-child(2) {
              }
            }

            a:nth-child(1) {
              position: relative;

              .bluebottom {
                display: none;
                position: absolute;
                top: 60px;
                left: -5px;
                width: 72px;
                height: 2px;
                background: #0085D0;
              }
            }

            .companyActive {
              color: #0085D0;

              div:nth-child(2) {
                display: block;
              }
            }

            .companyDefault {
            }
          }
        }
      }

      .contentMain {
        // width: 1280px;
        // margin: 0 auto;
        .companyIntro {
          // padding: 119px 319px;
          width: 1280px;
          margin: 0 auto;
          padding: 119px 0;
          display: flex;

          .IntroLeft {
            padding-right: 24px;

            h4 {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
            }

            h4:nth-child(2) {
              margin: 24px 0 60px 0;
              font-size: 26px;
              font-weight: 400;
            }

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 30px;
              color: #333333;
            }

            p:nth-child(3) {
              margin: 60px 0 30px 0;
              font-weight: 400;
              color: #0085D0;
            }

            p:nth-child(4) {
              margin-bottom: 40px;
            }
          }

          .introRight {
            >img {
              width: 498px;
              height: 514px;
            }
          }
        }

        .serveSupport {
          width: 1280px;
          margin: 0 auto;

          // padding: 0 319px 0 319px;
          .serveSupportTop {
            p {
              text-align: center;
              font-size: 30px;
              font-weight: 500;
              color: #333333;
              line-height: 39px;
              margin-bottom: 41px;
            }

            .blueBttom {
              text-align: center;
              margin: 41px auto;
              width: 42px;
              border-bottom: 5px solid #02B2B5;
            }
          }

          .serveSupportBottom {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .img {
              margin-bottom: 38px;
              width: 392px;
              height: 557px;
              position: relative;

              &:hover {
                .hoverResult {
                  transition-duration: 0.8s;
                  transform: translateY(-400px);
                  opacity: 1;
                }

                p {
                  display: none;
                }
              }

              p {
                position: absolute;
                bottom: 41px;
                left: 27px;
                font-size: 30px;
                font-weight: 500;
                color: #FFFFFF;
              }

              .hoverResult {
                position: absolute;
                top: 550px;
                left: 25px;
                opacity: 0;

                h4 {
                  margin-bottom: 18px;
                  height: 23px;
                  font-size: 24px;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 39px;
                }
              }
            }
          }
        }

        .croBusiness {
          width: 1280px;
          margin: 0 auto;

          p {
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            line-height: 39px;
            margin-bottom: 41px;
          }

          .blueBttom {
            text-align: center;
            margin: 41px auto;
            width: 42px;
            border-bottom: 5px solid #0085D0;
          }
        }

        .cro {
          // padding: 0 319px;
          background: #F8F9FA;

          .crobottom {
            width: 1280px;
            margin: 0 auto;
            // padding: 135px 0 151px 0;
            width: 1280px;
            margin: 0 auto;
            padding-top: 120px;
            padding-bottom: 140px;

            .crobottomTotal {
              display: flex;

              .croLeft {
                h4 {
                  margin: 81px 0 45px 0;
                  height: 39px;
                  font-size: 40px;
                  font-weight: 500;
                  color: #333333;
                }

                p {
                  margin-right: 134px;
                  height: 137px;
                  font-size: 20px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                  line-height: 39px;
                }
              }
            }
          }
        }

        .pbm {
          width: 1280px;
          margin: 0 auto;
          // padding: 135px 319px 151px;
          padding: 135px 0 151px;
          display: flex;

          .pbmLeft {
          }

          .pbmRight {
            margin-left: 114px;

            h4 {
              margin: 81px 0 45px 0;
              height: 39px;
              font-size: 40px;
              font-weight: 500;
              color: #333333;
            }

            p {
              height: 137px;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              line-height: 39px;
            }
          }
        }

        .healthGroup {
          // padding: 0 319px;
          background: #F8F9FA;

          .healthGroupbottom {
            width: 1280px;
            margin: 0 auto;
            padding: 135px 0 151px 0;

            .healthGroupbottomTotal {
              display: flex;

              .healthGroupLeft {
                h4 {
                  margin: 81px 0 45px 0;
                  height: 39px;
                  font-size: 40px;
                  font-weight: 500;
                  color: #333333;
                }

                p {
                  margin-right: 134px;
                  height: 137px;
                  font-size: 20px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                  line-height: 39px;
                }
              }
            }
          }
        }

        .advantage {
          width: 1280px;
          margin: 0 auto;

          .advantageTop {
            h4 {
              margin-top: 120px;
              text-align: center;
              font-size: 30px;
              font-weight: 500;
              color: #333333;
            }

            .blueBottom {
              text-align: center;
              margin: 41px auto;
              width: 42px;
              border-bottom: 5px solid #0085D0;
            }
          }

          .advantageBottom {
            // padding: 0 319px;
            margin-bottom: 65px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
              text-align: center;
              width: 32.33%;
              border-right: 2px solid #F5F5F5;
              border-bottom: 2px solid #F5F5F5;

              img {
                margin-top: 53px;
              }

              h4 {
                height: 19px;
                font-size: 20px;
                font-weight: 500;
                color: #333333;
                margin: 30px 0 20px;
              }

              p {
                text-align: left;
                margin: 0 30px 30px;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
              }

              &:nth-child(5), &:nth-child(6), &:nth-child(8) {
                p {
                  text-align: center;
                }
              }

              &:nth-child(3), &:nth-child(6), &:nth-child(9) {
                border-right: none;
              }

              &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
</style>